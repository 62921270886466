<template>
    <div id="Infoshipp" class="flex flex-col w-full h-auto px-4 mb-12">
        <!-- Header -->
        <div class="flex w-full h-auto justify-center items-center mb-3">
            <span class="Helvetica text-2xl font-bold text-green-500 justify-center normal-case">Sourcing Information</span>
        </div>
            <!-- **********Shipping info*********** -->
                 <div class="flex bg-white w-full space-x-2">
                        <div class="flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg">
                            <div class="flex w-full mt-1">
                                <span class="rounded-full h-12 w-12 bg-gray-200">
                                    <i class="material-icons p-3 text-green-400">person</i>
                                </span>

                                <div class="ml-5 w-full pb-4">
                                    <div class="text-base mt-1 font-semibold text-gray-600 pb-10">
                                        Sourcing information
                                    </div>
                                    <div class="text-sm font-light text-gray-500"></div>
                                    <div class="tab-content">
                                      <div id="latest-tasks-new" class="tab-pane active">
                                          <div class="flex items-center">
                                              <div class="border-l-2 border-green-600 dark:border-green-500 pl-4">
                                                 <span  class="font-medium">Seller </span>
                                                 <div class="text-gray-600">{{ item.seller.fullName}}</div>
                                               </div>
                                          </div>
                                           <div class="flex items-center mt-5">
                                              <div class="border-l-2 border-green-600 dark:border-green-500 pl-4">
                                                 <span  class="font-medium">Pays D'achat </span>
                                                 <div class="text-gray-600">{{ $f.getCountryNameByCode(dataCountry,item.country)}}</div>
                                               </div>
                                          </div>
                                          <div v-if="item.facturationDate" class="flex items-center mt-5">
                                            <div class="border-l-2 border-green-600 dark:border-green-500 pl-4">
                                              <span  class="font-medium">Facturation date</span>
                                              <div class="text-gray-600">{{$moment(item.facturationDate).format('yyyy-MM-DD')}}</div>
                                            </div>
                                          </div>
                                          <div v-if="item.validationDate" class="flex items-center mt-5">
                                            <div class="border-l-2 border-green-600 dark:border-green-500 pl-4">
                                              <span  class="font-medium">ValidationDate date</span>
                                              <div class="text-gray-600">{{$moment(item.validationDate).format('yyyy-MM-DD')}}</div>
                                            </div>
                                          </div>
                                          <div class="flex items-center mt-5">
                                            <div class="border-l-2 border-green-600 dark:border-green-500 pl-4">
                                              <span  class="font-medium">Amount</span>
                                              <div class="text-gray-600">{{ QuantityTotalOrPrices('price',item.details)+QuantityTotalOrPrices('fees',item.fees)  }} <sup>{{currency}}</sup></div>
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        
                          <!-- ****************************** Order Information *********************************************-->

                    <div class="flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg">
                        <div class="flex w-full mt-1">
                            <span class="rounded-full h-12 w-12 bg-gray-200">
                                <i class="material-icons p-3 text-green-400">local_grocery_store</i>
                            </span>

                            <div class="ml-5 w-full">
                                <div class="text-base mt-1 font-semibold text-gray-600 pb-10">
                                     Info to update
                                </div>

                                <div class="w-full space-y-4">
                                  
                                        <div 
                                            class="border hover:border-green-400 w-full focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                                            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                                <p>
                                                    <label for="status" :style="colorStatus(item.status)" class="bg-white text-gray-700 px-1">Status
                                                        <span :class="$colors.required">*</span>
                                                    </label>
                                                </p>
                                            </div>
                                            <p>
                                             <select  :style="colorStatus(item.status)"  v-model="item.status" class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white">
                                                <option class="bg-white" selected :value="null"> Status </option>
                                                <option class="bg-white text-gray-600" v-for="(value, index) in statusShipp" :key="index" :value="value">
                                                    {{ value }}
                                                </option>
                                            </select>
                                            </p>
                                        </div>
                                        
                                       
                                        
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                    <!--*******************  Product Order ***************************************-->
                    <div class="flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg">
                        <div class="flex w-full mt-1">
                            <span class="rounded-full h-12 w-12 bg-gray-200">
                                <i class="material-icons p-3 text-green-400">local_mall</i>
                            </span>

                            <div class="ml-5 w-full pb-4">
                                <div class="text-base mt-1 font-semibold text-gray-600 pb-10">
                                    Sourcing Products
                                </div>

                                <div class="w-full space-y-4">
                                    <div class="w-full">
                                        <table style="display: inline-table;"
                                            class="w-full flex flex-row flex-no-wrap sm:bg-white rounded-md overflow-hidden sm:shadow-xs">
                                            <thead class="text-white">
                                                <tr
                                                    class="bg-green-400 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                                                    <th class="p-2 justify-center">Image</th>
                                                    <th class="p-2 justify-center">Name</th>
                                                    <th class="p-2 justify-center">Category</th>
                                                    <th class="p-2 justify-center">Quantity</th>
                                                    <th class="p-2 justify-center">Price (MAD)</th>
                                                    <th class="p-2 justify-center">Price <span v-if="currency">({{currency}})</span></th>
                                                </tr>
                                            </thead>
                                            <tbody class="flex-1 sm:flex-none">
                                            <tr v-for="it in item.details" :key="it._id" class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                                                <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2">
                                                    <div> <img :alt="it.productObject.productName" :src="it.productObject.image" class="h-16 w-16 rounded-md mx-auto" /> </div>
                                                </td>
                                                <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"><b>{{ it.productObject.productName }}</b></td>
                                                <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"><b>{{ it.category }}</b></td>
                                                <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                    {{ it.quantity }}
                                                </td>
                                                 <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                    {{ it.priceDH }}
                                                </td>
                                                 <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                    {{ it.price }}
                                                </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </div>
                                     <div v-if="item.fees && item.fees.length>0" class="w-full">
                                        <h4 class="other-fees mb-3">Autre Fees</h4>
                                        <table style="display: inline-table;"
                                            class="w-full flex flex-row flex-no-wrap sm:bg-white rounded-md overflow-hidden sm:shadow-xs">
                                            <thead class="text-white">
                                                <tr
                                                    class="bg-green-400 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                                                    <th class="p-2 justify-center">Fees name</th>
                                                    <th class="p-2 justify-center">Price (MAD)</th>
                                                    <th class="p-2 justify-center">Price <span v-if="currency">({{currency}})</span></th>
                                                </tr>
                                            </thead>
                                            <tbody class="flex-1 sm:flex-none">
                                            <tr v-for="it in item.fees" :key="it._id" class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                                               
                                                <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"><b>{{ it.name }}</b></td>
                                                 <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                    {{ it.priceDH }}
                                                </td>
                                                 <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                    {{ it.price }}
                                                </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            <!-- **************/Shipping info****************-->
    </div>
</template>
<script>
export default {
    props: {
        config: { required: true, type: Object},
        item:{ default: [] },
        index:{ type: Number },
        currentUser:{ type: Object },
        //statusShipp:{ type: Array }, 
        dataCountry:{ type: Array }, 
        currency:{ type: String },
    },
    data() {
        return {
            statusShipp:[]
        }
    },
    async mounted() {
       if(this.item.status === 'pending') this.statusShipp=['pending','rejected'];
       else this.statusShipp=['pending','en attente de paiement','en attente de validation','valide','rejected'];
    },   
    methods: {
    setSelected(courier) {
        console.log(courier)  
    },
    QuantityTotalOrPrices(type,data){
        var value=0;
        for (let i in data) {
            if(type=='qte') value+=data[i].quantity;
            else if(type=='fees') value+=data[i].price;
            else value+=(parseFloat(data[i].price)*parseFloat(data[i].quantity));
            
        }
        return value;
    },
    colorStatus(status) {
        switch (status) {
                case 'pending':
                return "color: rgb(233 147 19); border-color: rgb(233 147 19);"
                break;
            case 'en attente de paiement':
                return "color:rgb(8, 194, 226); border-color: rgb(8, 194, 226);"
                break;
            
            case 'en attente de validation':
                    return "color:rgb(18 23 217); border-color: rgb(18 23 217);"
                    break;
                
            case 'rejected':
                return "color: rgb(255, 5, 5); border-color: rgb(255, 5, 5);"
                break;
        }
        },
    },
}
</script>
<style scoped>
 .seller-select .vs__dropdown-toggle {
    border: none;
}
</style>